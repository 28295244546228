// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { ShellBackMaterialRow } from '../typings';

const data = [
  {
    "id": "carbonFiberMesh",
    "props": {
      "materialIdSuffix": "carbonFiber",
      "hasExtraStitching": true
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.carbonFiberMesh.name')
  },
  {
    "id": "carbonFiberBlack",
    "props": {
      "materialIdSuffix": "carbonFiberBlack",
      "hasExtraStitching": true
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.carbonFiberBlack.name')
  },
  {
    "id": "speedShellMesh",
    "props": {
      "materialIdSuffix": "speedShell",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.speedShellMesh.name')
  },
  {
    "id": "speedShellCamel",
    "props": {
      "materialIdSuffix": "speedShellCamel",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.speedShellCamel.name')
  },
  {
    "id": "zebraKnitMesh",
    "props": {
      "materialIdSuffix": "zebraKnit",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": false,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.zebraKnitMesh.name')
  },
  {
    "id": "blackCrocMesh",
    "props": {
      "materialIdSuffix": "croc",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.blackCrocMesh.name')
  },
  {
    "id": "blackMesh",
    "props": {
      "materialIdSuffix": "black",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.blackMesh.name')
  },
  {
    "id": "brownMesh",
    "props": {
      "materialIdSuffix": "brown",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.brownMesh.name')
  },
  {
    "id": "camoMesh",
    "props": {
      "materialIdSuffix": "camo",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.camoMesh.name')
  },
  {
    "id": "bbColumbia-gradient",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.bbColumbia-gradient.name')
  },
  {
    "id": "black-geo",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.black-geo.name')
  },
  {
    "id": "black-gradient",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.black-gradient.name')
  },
  {
    "id": "black-shatter",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.black-shatter.name')
  },
  {
    "id": "blackSlime-gradient",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.blackSlime-gradient.name')
  },
  {
    "id": "blueGreen-gradient",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.blueGreen-gradient.name')
  },
  {
    "id": "columbiaBlue-geo",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.columbiaBlue-geo.name')
  },
  {
    "id": "navy-geo",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.navy-geo.name')
  },
  {
    "id": "navy-shatter",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.navy-shatter.name')
  },
  {
    "id": "navyGold-gradient",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.navyGold-gradient.name')
  },
  {
    "id": "navyScarlet-shatter",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.navyScarlet-shatter.name')
  },
  {
    "id": "purple-geo",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.purple-geo.name')
  },
  {
    "id": "redBlack-shatter",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.redBlack-shatter.name')
  },
  {
    "id": "royal-geo",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.royal-geo.name')
  },
  {
    "id": "navyScarlet-geo",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.navyScarlet-geo.name')
  },
  {
    "id": "purplePink-gradient",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.purplePink-gradient.name')
  },
  {
    "id": "smileys",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.smileys.name')
  },
  {
    "id": "tiedye",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.tiedye.name')
  },
  {
    "id": "usa",
    "props": {
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.shellBackMaterials.usa.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ShellBackMaterialRow[]>> = data;

export type ShellBackMaterial = typeof typed[number];

export const SHELL_BACK_MATERIAL_INDEX_KEY = "id";
export type ShellBackMaterialIndexKey = "id";
export type ShellBackMaterialId = ShellBackMaterial["id"];

let i = 0;
export const SHELL_BACK_MATERIAL_DICT = {
  "carbonFiberMesh": typed[i++],
  "carbonFiberBlack": typed[i++],
  "speedShellMesh": typed[i++],
  "speedShellCamel": typed[i++],
  "zebraKnitMesh": typed[i++],
  "blackCrocMesh": typed[i++],
  "blackMesh": typed[i++],
  "brownMesh": typed[i++],
  "camoMesh": typed[i++],
  // "bbColumbia-gradient": typed[i++],
  // "black-geo": typed[i++],
  // "black-gradient": typed[i++],
  // "black-shatter": typed[i++],
  // "blackSlime-gradient": typed[i++],
  // "blueGreen-gradient": typed[i++],
  // "columbiaBlue-geo": typed[i++],
  // "navy-geo": typed[i++],
  // "navy-shatter": typed[i++],
  // "navyGold-gradient": typed[i++],
  // "navyScarlet-shatter": typed[i++],
  // "purple-geo": typed[i++],
  // "redBlack-shatter": typed[i++],
  // "royal-geo": typed[i++],
  // "navyScarlet-geo": typed[i++],
  // "purplePink-gradient": typed[i++],
  "smileys": typed[i++],
  "tiedye": typed[i++],
  "usa": typed[i++]
} as const;

export { typed as SHELL_BACK_MATERIALS };
